import { css } from "styled-components";
import * as fontFiles from "./fontImports";

const fontFaces = css`
  @font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    src: local("Poppins"), url(${fontFiles.PoppinsRegularTTF}) format("truetype");
  }

  @font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    src: local("Poppins"), url(${fontFiles.PoppinsMediumTTF}) format("truetype");
  }

  @font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    src: local("Poppins"), url(${fontFiles.PoppinsSemiBoldTTF}) format("truetype");
  }

  @font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    src: local("Poppins"), url(${fontFiles.PoppinsBoldTTF}) format("truetype");
  }
`;

export default fontFaces;