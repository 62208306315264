import { DefaultTheme } from "styled-components";
import breakpoints from "./breakpoints/breakpoints";
import { min, max, below, onlyOn } from "./breakpoints/mediaQueries";

const colors = {
  light: "#ffffff",
  mainLight: "#4888e8",
  mainMid: "#3677d7",
  mainDark: "#1658ba",
  grayMid: "#c4c4c4",
};

const gradients = {
  default: `linear-gradient(${colors.mainLight}, ${colors.mainDark})`,
  main: (deg: number) =>
    `linear-gradient(${deg}deg, ${colors.mainLight}, ${colors.mainDark})`,
};

const fontWeights = {
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
};

const media = {
  min,
  max,
  below,
  onlyOn,
};

const theme: DefaultTheme = {
  colors,
  gradients,
  breakpoints,
  media,
  fontWeights,
  maxWidth: 1440,
};

export default theme;
