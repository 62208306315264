import { css } from "styled-components";

const animations = css`
  .faded {
    opacity: 0;
  }

  @keyframes slide-in-right {
    from {
      transform: translateX(50px);
      opacity: 0;
    }

    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-left {
    from {
      transform: translateX(-50px);
      opacity: 0;
    }

    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-bottom {
    from {
      transform: translateY(50px);
      opacity: 0;
    }

    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-origin {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .dots-scale {
    opacity: 1;

    circle {
      transform-origin: center;
      animation: dots-scale 0.85s both;

      ${({theme}) => theme.media.min.laptop`
        animation-delay: 1.25s;
      `}
    }

    @keyframes dots-scale {
      from {
        r: 0;
      }
      to {
        r: 5;
      }
    }
  }
`;

export default animations;
