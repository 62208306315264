import { createGlobalStyle } from "styled-components";
import fontFaces from "./typography/fontFaces";
import animations from "./animations";

const GlobalStyle = createGlobalStyle`
  ${fontFaces}
  ${animations}

  *, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html, body, button, input, textarea, :-webkit-autofill::first-line {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-family: "Poppins", sans-serif;
    scroll-behavior: smooth;
  }

  .swiper {
    overflow: visible !important;
  }

  .swiper-wrapper {
    align-items: center;
  }
`;

export default GlobalStyle;
