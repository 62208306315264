import { css } from "styled-components";
import breakpoints from "./breakpoints";

const keys = Object.keys(breakpoints) as Array<keyof typeof breakpoints>;

export const min = keys.reduce((acc, label) => {
  acc[label] = (literals: TemplateStringsArray, ...placeholders: any[]) =>
    css`
      @media (min-width: ${breakpoints[label]}px) {
        ${css(literals, ...placeholders)};
      }
    `.join("");
  return acc;
}, {} as Record<keyof typeof breakpoints, (l: TemplateStringsArray, ...p: any[]) => string>);

export const max = keys.reduce((acc, label) => {
  acc[label] = (literals: TemplateStringsArray, ...placeholders: any[]) =>
    css`
      @media (max-width: ${breakpoints[label]}px) {
        ${css(literals, ...placeholders)};
      }
    `.join("");
  return acc;
}, {} as Record<keyof typeof breakpoints, (l: TemplateStringsArray, ...p: any[]) => string>);

export const below = keys.reduce((acc, label) => {
  acc[label] = (literals: TemplateStringsArray, ...placeholders: any[]) =>
    css`
      @media (max-width: ${breakpoints[label] - 1}px) {
        ${css(literals, ...placeholders)};
      }
    `.join("");
  return acc;
}, {} as Record<keyof typeof breakpoints, (l: TemplateStringsArray, ...p: any[]) => string>);

export const onlyOn = keys.reduce((acc, label, currentIndex) => {
  acc[label] = (literals: TemplateStringsArray, ...placeholders: any[]) =>
    css`
      @media (min-width: ${breakpoints[label]}px) and (max-width: ${Object.values(breakpoints)[currentIndex + 1] - 1}px) {
        ${css(literals, ...placeholders)};
      }
    `.join("");
  return acc;
}, {} as Record<keyof typeof breakpoints, (l: TemplateStringsArray, ...p: any[]) => string>);
