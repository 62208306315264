import styled from "styled-components";

const Paragraph = styled.p<{ spaced?: boolean, colored?: boolean }>`
  max-width: 70ch;
  margin: 24px 16px;
  text-align: center;
  line-height: ${({ spaced }) => (spaced ? 2 : 1.5)};
  font-size: clamp(0.75rem, 3.5vw, 1rem);
  font-weight: ${({theme}) => theme.fontWeights.medium};

  ${({theme, colored}) => colored && `
    background: -webkit-linear-gradient(${theme.colors.mainLight}, ${theme.colors.mainDark});
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `}

  ${({ theme }) => theme.media.min.laptop`
    margin: 36px 0;
    text-align: left;
  `}
`;

export default Paragraph;
