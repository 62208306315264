import React from "react";
import ReactHelmet from "react-helmet";
import AppleTouchIcon from "assets/images/favicon/apple-touch-icon.png";
import Favicon16 from "assets/images/favicon/favicon-16x16.png";
import Favicon32 from "assets/images/favicon/favicon-32x32.png";

interface HelmetProps {
  customTitle?: string;
}

const Helmet: React.FC<HelmetProps> = (props: HelmetProps) => {
  return (
    <ReactHelmet>
      <meta charSet="utf-8" />
      <meta lang="pl" />
      <meta
        name="description"
        content={
          "Chciałbyś zwiększyć liczbę klientów? " +
          "Potrzebujesz reklamy w social media? " +
          "A może interesuje cię strona internetowa lub identyfikacja wizualna? " +
          "Jeśli na którekolwiek z powyższych pytań odpowiedziałeś twierdząco, to trafiłeś w dobre miejsce!"
        }
      />
      <meta
        name="keywords"
        content="agencja, reklama, facebook, instagram, strony, internetowe, grafika, design, beauty"
      />
      <meta name="author" content="Miłosz Kowalski" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="theme-color" content="#3677d7" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={AppleTouchIcon}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={Favicon32}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={Favicon16}
      />
      <link rel="manifest" href="/site.webmanifest" />
      <title>{props.customTitle || "Agencja FollowUP - wespnij się na wyżyny biznesu"}</title>
    </ReactHelmet>
  );
};

export default Helmet;
